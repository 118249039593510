import { useContext, useLayoutEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DEFAULT_TEMPLATE_ID } from 'utils/constants';
import { AuthCookieContext } from 'utils/context';
import { templatesRegistry } from 'utils/templates';

export const TemplateWrapper = () => {
  const { userInfo, setUserInfo } = useContext(AuthCookieContext);
  const { templateId: templateIdParams } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const templateIds = templatesRegistry.getTemplateIds();
    const templateId = templateIds.includes(templateIdParams)
      ? templateIdParams
      : DEFAULT_TEMPLATE_ID;

    if (templateIdParams && templateId !== templateIdParams) {
      const path = location.pathname.replace(templateIdParams, templateId);
      navigate(path, { replace: true });
    }

    if (userInfo?.templateId !== templateId) {
      setUserInfo((prev) => ({
        ...prev,
        templateId,
      }));
    }
  }, [
    location.pathname,
    navigate,
    setUserInfo,
    templateIdParams,
    userInfo?.templateId,
  ]);
  return <Outlet />;
};
