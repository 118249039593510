import SelectButton from 'components/SelectButton';
import {
  WEB_HEATING_INTEREST_STARTED,
  WEB_HEATING_INTEREST_STARTED_2,
  WEB_HEATING_INTEREST_STARTED_3,
  WEB_HEATING_INTEREST_STARTED_4,
  WEB_HEATING_INTEREST_STARTED_5,
  WEB_HEATING_INTEREST_STARTED_6,
  WEB_LAST_STEP_ANALYSING_STARTED,
  WEB_LAST_STEP_ANALYSING_STARTED_3,
  WEB_LAST_STEP_ANALYSING_STARTED_4,
  WEB_SETTING_USER_GOAL_STARTED,
  WEB_SETTING_USER_GOAL_STARTED_2,
  WEB_SETTING_USER_GOAL_STARTED_3,
  WEB_SHARING_ABOUT_USER_STARTED,
  WEB_SHARING_ABOUT_USER_STARTED_10,
  WEB_SHARING_ABOUT_USER_STARTED_11,
  WEB_SHARING_ABOUT_USER_STARTED_12,
  WEB_SHARING_ABOUT_USER_STARTED_13,
  WEB_SHARING_ABOUT_USER_STARTED_14,
  WEB_SHARING_ABOUT_USER_STARTED_15,
  WEB_SHARING_ABOUT_USER_STARTED_16,
  WEB_SHARING_ABOUT_USER_STARTED_2,
  WEB_SHARING_ABOUT_USER_STARTED_3,
  WEB_SHARING_ABOUT_USER_STARTED_4,
  WEB_SHARING_ABOUT_USER_STARTED_5,
  WEB_SHARING_ABOUT_USER_STARTED_6,
  WEB_SHARING_ABOUT_USER_STARTED_7,
  WEB_SHARING_ABOUT_USER_STARTED_8,
  WEB_SHARING_ABOUT_USER_STARTED_9,
  WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED,
  WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_2,
  WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_3,
  WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_4,
  WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_5,
} from './AmplitudeTrackEvents';
import MultiCheckbox from 'components/MultiCheckbox';
import YesNoButtons from 'layouts/components/YesNoButtons';

export const QUIZ_CONFIG = {
  startPage: 'Page1',
  Page1: {
    eventName: WEB_HEATING_INTEREST_STARTED,
    nextPage: 'Page2',
  },
  Page2: {
    eventName: WEB_HEATING_INTEREST_STARTED_2,
    nextPage: 'Page3',
  },
  Page3: {
    eventName: WEB_HEATING_INTEREST_STARTED_3,
    nextPage: 'Page4',
  },
  Page4: {
    eventName: WEB_HEATING_INTEREST_STARTED_4,
    nextPage: 'AllInOneApp',
  },
  AllInOneApp: {
    eventName: WEB_HEATING_INTEREST_STARTED_5,
    nextPage: 'Page6',
  },
  Page6: {
    eventName: WEB_HEATING_INTEREST_STARTED_6,
    nextPage: 'Page7',
  },
  Page7: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED,
    btnText: 'dynamicPage.buttons.lets_go',
    nextPage: 'Page8',
  },
  Page8: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_2,
    sectionName: 'dynamicPage.sectionName.goal',
    label: 'dynamicPage.labels.primary_goal',
    QuestionButtonType: SelectButton,
    activeStep: 1,
    btnText: 'dynamicPage.buttons.next',
    questions: [
      {
        value: 'grow_followers',
        label: 'dynamicPage.questions.grow_followers',
      },
      {
        value: 'boost_engagement',
        label: 'dynamicPage.questions.boost_engagement',
      },
      {
        value: 'gain_more_sales_for_my_business',
        label: 'dynamicPage.questions.gain_more_sales_for_my_business',
      },
      {
        value: 'get_higher_engagement_rate',
        label: 'dynamicPage.questions.get_higher_engagement_rate',
      },
      {
        value: 'automate_content_creation',
        label: 'dynamicPage.questions.automate_content_creation',
      },
    ],
    nextPage: 'Page9',
  },
  Page9: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_3,
    sectionName: 'dynamicPage.sectionName.describe_yourself',
    label: 'dynamicPage.labels.going_to_use',
    QuestionButtonType: SelectButton,
    activeStep: 2,
    btnText: 'dynamicPage.buttons.next',
    questions: [
      {
        value: 'business_owner',
        label: 'dynamicPage.questions.business_owner',
      },
      {
        value: 'marketing_professional',
        label: 'dynamicPage.questions.marketing_professional',
      },
      {
        value: 'influencer_or_blogger',
        label: 'dynamicPage.questions.influencer_or_blogger',
      },
      {
        value: 'personal_use',
        label: 'dynamicPage.questions.personal_use',
      },
      {
        value: 'other',
        label: 'common.other',
      },
    ],
    nextPage: 'Page10',
  },
  Page10: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_4,
    sectionName: 'dynamicPage.sectionName.describe_yourself',
    label: 'dynamicPage.labels.content_about',
    QuestionButtonType: MultiCheckbox,
    activeStep: 2,
    btnText: 'dynamicPage.buttons.next',
    questions: [
      {
        value: 'e-commerce',
        label: 'dynamicPage.questions.e-commerce',
      },
      {
        value: 'beauty',
        label: 'dynamicPage.questions.beauty',
      },
      {
        value: 'fashion',
        label: 'dynamicPage.questions.fashion',
      },
      {
        value: 'Health & Fitness',
        label: 'dynamicPage.questions.health_&_fitness',
      },
      {
        value: 'Photography',
        label: 'dynamicPage.questions.photography',
      },
      {
        value: 'Lifestyle',
        label: 'dynamicPage.questions.lifestyle',
      },
      {
        value: 'Art & Design',
        label: 'dynamicPage.questions.art_&_design',
      },
      {
        value: 'Food',
        label: 'dynamicPage.questions.food',
      },
      {
        value: 'Education',
        label: 'dynamicPage.questions.education',
      },
      {
        value: 'Music',
        label: 'dynamicPage.questions.music',
      },
      {
        value: 'Travel',
        label: 'dynamicPage.questions.travel',
      },
    ],
    nextPage: 'Page11',
  },
  Page11: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_5,
    sectionName: 'dynamicPage.sectionName.instagram_activity',
    label: 'dynamicPage.labels.spend_time',
    QuestionButtonType: SelectButton,
    activeStep: 3,
    btnText: 'dynamicPage.buttons.next',
    questions: [
      {
        value: '1_hour_a_week',
        label: 'dynamicPage.questions.1_hour_a_week',
      },
      {
        value: '2_3_hours_a_week',
        label: 'dynamicPage.questions.2_3_hours_a_week',
      },
      {
        value: '4_5_hours_a_week',
        label: 'dynamicPage.questions.4_5_hours_a_week',
      },
      {
        value: '5_10_hours_a_week',
        label: 'dynamicPage.questions.5_10_hours_a_week',
      },
      {
        value: '10_20_hours_a_week',
        label: 'dynamicPage.questions.10_20_hours_a_week',
      },
      {
        value: 'more_then_20_hours_a_week',
        label: 'dynamicPage.questions.more_then_20_hours_a_week',
      },
    ],
    nextPage: 'Page12',
  },
  Page12: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_6,
    sectionName: 'dynamicPage.sectionName.instagram_activity',
    label: 'dynamicPage.labels.followers_you_have',
    activeStep: 3,
    QuestionButtonType: SelectButton,
    questions: [
      {
        value: 'less_then_100',
        label: '< 100',
      },
      {
        value: '101_500',
        label: '101 - 500',
      },
      {
        value: '501_1000',
        label: '501 - 1000',
      },
      {
        value: '1001_10000',
        label: '1001 - 10000',
      },
      {
        value: '10001_50000',
        label: '10 001 - 50 000',
      },
      {
        value: '50001_100000',
        label: '50 001 - 100 000',
      },
    ],
    nextPage: 'Page13',
  },
  Page13: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_7,
    sectionName: 'dynamicPage.sectionName.instagram_activity',
    label: 'dynamicPage.labels.posts_last_week',
    QuestionButtonType: SelectButton,
    activeStep: 3,
    questions: [
      {
        value: '0',
        label: '0',
      },
      {
        value: '1_2',
        label: '1 - 2',
      },
      {
        value: '3_4',
        label: '3 - 4',
      },
      {
        value: '5_6',
        label: '5 - 6',
      },
      {
        value: '7_8',
        label: '7 - 8',
      },
      {
        value: '9_10',
        label: '9 - 10',
      },
    ],
    nextPage: 'Page14',
  },
  Page14: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_8,
    sectionName: 'dynamicPage.sectionName.instagram_activity',
    label: 'dynamicPage.labels.stories_last_week',
    QuestionButtonType: SelectButton,
    activeStep: 3,
    questions: [
      {
        value: '0',
        label: '0',
      },
      {
        value: '1_2',
        label: '1 - 2',
      },
      {
        value: '3_4',
        label: '3 - 4',
      },
      {
        value: '5_6',
        label: '5 - 6',
      },
      {
        value: '7_8',
        label: '7 - 8',
      },
      {
        value: '9_10',
        label: '9 - 10',
      },
    ],
    nextPage: 'Page15',
  },
  Page15: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_9,
    sectionName: 'dynamicPage.sectionName.instagram_activity',
    label: 'dynamicPage.labels.reels_last_week',
    activeStep: 3,
    QuestionButtonType: SelectButton,
    questions: [
      {
        value: '0',
        label: '0',
      },
      {
        value: '1_2',
        label: '1 - 2',
      },
      {
        value: '3_4',
        label: '3 - 4',
      },
      {
        value: '5_6',
        label: '5 - 6',
      },
      {
        value: '7_8',
        label: '7 - 8',
      },
      {
        value: '9_10',
        label: '9 - 10',
      },
    ],
    nextPage: 'Page16',
  },
  Page16: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_10,
    nextPage: 'Page17',
  },
  Page17: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_11,
    sectionName: 'dynamicPage.sectionName.ai_automation',
    label: 'dynamicPage.labels.helps_with_content',
    ButtonType: YesNoButtons,
    activeStep: 4,
    nextPage: 'Page18',
  },
  Page18: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_12,
    sectionName: 'dynamicPage.sectionName.ai_automation',
    label: 'dynamicPage.labels.ai_tools',
    ButtonType: YesNoButtons,
    activeStep: 4,
    nextPage: 'Page19',
  },
  Page19: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_13,
    sectionName: 'dynamicPage.sectionName.ai_automation',
    label: 'dynamicPage.labels.type_of_content',
    QuestionButtonType: MultiCheckbox,
    activeStep: 4,
    questions: [
      {
        value: 'video_scripts',
        label: 'dynamicPage.questions.video_scripts',
      },
      {
        value: 'video_hooks',
        label: 'dynamicPage.questions.video_hooks',
      },
      {
        value: 'short_videos',
        label: 'dynamicPage.questions.short_videos',
      },
      {
        value: 'pictures_photos',
        label: 'dynamicPage.questions.pictures_photos',
      },
      {
        value: 'my_avatars',
        label: 'dynamicPage.questions.my_avatars',
      },
      {
        value: 'content_ideas',
        label: 'dynamicPage.questions.content_ideas',
      },
      {
        value: 'Other',
        label: 'common.other',
      },
    ],
    nextPage: 'Page20',
  },
  Page20: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_14,
    nextPage: 'Page21',
  },
  Page21: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_15,
    sectionName: 'dynamicPage.sectionName.monetization',
    label: 'dynamicPage.labels.monetize',
    ButtonType: YesNoButtons,
    activeStep: 5,
    nextPage: 'Page22',
  },
  Page22: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_16,
    sectionName: 'dynamicPage.sectionName.monetization',
    label: 'dynamicPage.labels.is_monetized',
    QuestionButtonType: SelectButton,
    activeStep: 5,
    questions: [
      {
        value: 'not_yet',
        label: 'dynamicPage.questions.not_yet',
      },
      {
        value: 'made_over_5000',
        label: 'dynamicPage.questions.made_over_5000',
      },
      {
        value: 'made_over_10000',
        label: 'dynamicPage.questions.made_over_10000',
      },
      {
        value: 'this_is_my_main_source_of_income',
        label: 'dynamicPage.questions.this_is_my_main_source_of_income',
      },
      {
        value: 'prefer_not_to_answer',
        label: 'dynamicPage.questions.prefer_not_to_answer',
      },
    ],
    btnText: 'dynamicPage.buttons.next',
    nextPage: 'Page23',
  },
  Page23: {
    eventName: WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED,
    sectionName: 'dynamicPage.sectionName.preferences',
    label: 'dynamicPage.labels.choose_a_story',
    activeStep: 6,
    btnText: 'dynamicPage.buttons.this_one',
    nextPage: 'Page24',
  },
  Page24: {
    eventName: WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_2,
    sectionName: 'dynamicPage.sectionName.preferences',
    label: 'dynamicPage.labels.choose_a_story',
    activeStep: 6,
    btnText: 'dynamicPage.buttons.this_one',
    nextPage: 'Page25',
  },
  Page25: {
    eventName: WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_3,
    sectionName: 'dynamicPage.sectionName.preferences',
    label: 'dynamicPage.labels.ai_background_remover',
    activeStep: 6,
    ButtonType: YesNoButtons,
    nextPage: 'Page26',
  },
  Page26: {
    eventName: WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_4,
    sectionName: 'dynamicPage.sectionName.preferences',
    label: 'dynamicPage.labels.outlines',
    activeStep: 6,
    ButtonType: YesNoButtons,
    nextPage: 'Page27',
  },
  Page27: {
    eventName: WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_5,
    nextPage: 'Page28',
  },
  Page28: {
    eventName: WEB_SETTING_USER_GOAL_STARTED,
    sectionName: 'dynamicPage.sectionName.set_your_goal',
    label: 'dynamicPage.labels.followers_you_want',
    activeStep: 7,
    btnText: 'dynamicPage.buttons.next',
    nextPage: 'Page29',
  },
  Page29: {
    eventName: WEB_SETTING_USER_GOAL_STARTED_2,
    sectionName: 'dynamicPage.sectionName.set_your_goal',
    label: 'dynamicPage.labels.monthly_income',
    QuestionButtonType: SelectButton,
    activeStep: 7,
    questions: [
      {
        value: '1000',
        label: '$1000',
      },
      {
        value: '1000_5000',
        label: '$1000 - $5000',
      },
      {
        value: '5000_10000',
        label: '$5000 - $10000',
      },
      {
        value: '10000_15000',
        label: '$10000 - $15000',
      },
      {
        value: 'prefer_not_to_answer',
        label: 'dynamicPage.questions.prefer_not_to_answer',
      },
    ],
    btnText: 'dynamicPage.buttons.next',
    nextPage: 'Page30',
  },
  Page30: {
    eventName: WEB_SETTING_USER_GOAL_STARTED_3,
    nextPage: '../email',
  },
  email: {
    eventName: WEB_LAST_STEP_ANALYSING_STARTED,
    nextPage: 'Page31',
  },
  Page31: {
    eventName: WEB_LAST_STEP_ANALYSING_STARTED_3,
    nextPage: 'AnalyzingPage',
  },
  AnalyzingPage: {
    eventName: WEB_LAST_STEP_ANALYSING_STARTED_4,
    nextPage: '../../paywall',
  },
  paywall: {
    nextPage: 'subscriptions',
  },
};
