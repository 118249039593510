function parsePrice(price) {
  return Math.round(parseFloat(price) * 100);
}

export const PRICES_MAP = {
  '99_7day-3999_1month': {
    stripe: 'price_1QUUopIQoDVQPlsmj7aPCysZ',
    paypal: 'P-6FD03606MP5245234M5MFNJI',
    price: '39.99',
    prevPrice: '49.99',
    per: '1 month',
    trial: '7 day',
    trialPrice: '0.99',
    prevTrialPrice: '6.99',
    applePay: function (t, startingOn) {
      return {
        total: {
          label: t('applePay.total_label.HT9T45'),
          amount: parsePrice(this.trialPrice),
        },
        displayItems: [
          {
            label: t('applePay.displayItems.HT9T45.0'), // 'Monthly subscription with 7 days just $0.99 trial period then $29.99/month',
            amount: parsePrice(this.trialPrice),
          },
          {
            label: t(`applePay.displayItems.HT9T45.1`, { startingOn }), // 'Monthly subscription with 7 days just $0.99 trial period then $29.99/month',
            amount: parsePrice(this.price),
          },
        ],
      };
    },
  },
  '699_7day-3999_1month': {
    stripe: 'price_1QnKQqIQoDVQPlsmP92UzNNo',
    paypal: 'P-701221214N565892UM6ONDWI',
    price: '39.99',
    prevPrice: '49.99',
    per: '1 month',
    trial: '7 day',
    trialPrice: '6.99',
    prevTrialPrice: '13.99',
    applePay: function (t, startingOn) {
      return {
        total: {
          label: t('applePay.total_label.J7C4HK'),
          amount: parsePrice(this.trialPrice),
        },
        displayItems: [
          {
            label: t('applePay.displayItems.J7C4HK.0'), // 'Monthly subscription with 7 days just $6.99 trial period then $29.99/month',
            amount: parsePrice(this.trialPrice),
          },
          {
            label: t(`applePay.displayItems.J7C4HK.1`, { startingOn }), // 'Monthly subscription with 7 days just $6.99 trial period then $29.99/month',
            amount: parsePrice(this.price),
          },
        ],
      };
    },
  },
  '699_7day-2999_1month': {
    stripe: 'price_1QuwlUIQoDVQPlsmg6tZo2g3',
    paypal: 'P-2PM91624CR562592MMXLWHHQ',
    price: '29.99',
    prevPrice: '39.99',
    trialPrice: '6.99',
    per: '1 month',
    trial: '7 day',
    prevTrialPrice: '13.99',
    applePay: function (t, startingOn) {
      return {
        total: {
          label: t('applePay.total_label.J7C4HK'),
          amount: parsePrice(this.trialPrice),
        },
        displayItems: [
          {
            label: t('applePay.displayItems.J7C4HK.0'), // 'Monthly subscription with 7 days just $6.99 trial period then $29.99/month',
            amount: parsePrice(this.trialPrice),
          },
          {
            label: t(`applePay.displayItems.J7C4HK.1`, { startingOn }), // 'Monthly subscription with 7 days just $6.99 trial period then $29.99/month',
            amount: parsePrice(this.price),
          },
        ],
      };
    },
  },
  '1999_1month-2999_1month': {
    stripe: 'price_1QzJXhIQoDVQPlsmY9PaSN6X',
    paypal: 'P-8L078010NE991152TM7EHARQ',
    per: '1 month',
    price: '29.99',
    prevPrice: '39.99',
    trial: '1 month',
    trialPrice: '19.99',
    prevTrialPrice: '29.99',
    applePay: function (t, startingOn) {
      return {
        total: {
          label: t('applePay.total_label.1month-1month'),
          amount: parsePrice(this.trialPrice),
        },
        displayItems: [
          {
            label: t('applePay.displayItems.1month-1month.0'),
            amount: parsePrice(this.trialPrice),
          },
          {
            label: t(`applePay.displayItems.1month-1month.1`, {
              startingOn,
            }),
            amount: parsePrice(this.price),
          },
        ],
      };
    },
  },
  '2999_3month-5499_3month': {
    stripe: 'price_1QzJz9IQoDVQPlsmUmuRTnnr',
    paypal: 'P-06731771TE3195537M7EHBEY',
    per: '3 month',
    price: '54.99',
    prevPrice: '69.99',
    trial: '3 month',
    trialPrice: '29.99',
    prevTrialPrice: '54.99',
    applePay: function (t, startingOn) {
      return {
        total: {
          label: t('applePay.total_label.3month-3month'),
          amount: parsePrice(this.trialPrice),
        },
        displayItems: [
          {
            label: t('applePay.displayItems.3month-3month.0'),
            amount: parsePrice(this.trialPrice),
          },
          {
            label: t(`applePay.displayItems.3month-3month.1`, {
              startingOn,
            }),
            amount: parsePrice(this.price),
          },
        ],
      };
    },
  },

  '0-2999_6month': {
    stripe: process.env.REACT_APP_STRIPE_6_MONTHS,
    paypal: process.env.REACT_APP_PAYPAL_6_MONTHS,
    price: '29.99',
    prevPrice: '39.99',
    per: '6 months',
    trial: '0 day',
    trialPrice: 0,
    applePay: function (startingOn) {
      return {
        total: {
          label: '6 Months subscription',
          amount: parsePrice(this.trialPrice),
        },
        displayItems: [
          {
            label: `6 Month Plan: Starting on ${startingOn}`,
            amount: parsePrice(this.trialPrice),
          },
        ],
      };
    },
  },
  '0_7day-2999_1month': {
    stripe: process.env.REACT_APP_STRIPE_LETTER_01,
    paypal: process.env.REACT_APP_PAYPAL_LETTER_01,
    price: '29.99',
    prevPrice: '39.99',
    per: '1 month',
    trial: '7 day',
    trialPrice: 0,
    applePay: function (t, startingOn) {
      return {
        total: {
          label: t('applePay.total_label.1'),
          amount: 0,
          // type: 'pending'
        },
        displayItems: [
          {
            label: t(`applePay.displayItems.1.0`, { startingOn }),
            amount: 0,
            // type: 'final'
          },
          {
            label: t(`applePay.displayItems.1.1`, { startingOn }),
            amount: parsePrice(this.price),
            // type: 'final'
          },
        ],
      };
    },
  },
  '0_14day-2999_3month': {
    stripe: process.env.REACT_APP_STRIPE_LETTER_02,
    paypal: process.env.REACT_APP_PAYPAL_LETTER_02,
    price: '29.99',
    prevPrice: '39.99',
    per: '3 month',
    trial: '14 day',
    trialPrice: 0,
    applePay: function (t, startingOn) {
      return {
        total: {
          label: t('applePay.total_label.2'),
          amount: 0,
        },
        displayItems: [
          {
            label: t(`applePay.displayItems.2.0`, { startingOn }),
            amount: 0,
          },
          {
            label: t(`applePay.displayItems.2.1`, { startingOn }),
            amount: parsePrice(this.price),
          },
        ],
      };
    },
  },
  '0_14day-8999_1year': {
    stripe: process.env.REACT_APP_STRIPE_LETTER_07,
    paypal: process.env.REACT_APP_PAYPAL_LETTER_07,
    price: '89.99',
    prevPrice: '129.99',
    per: '1 year',
    trial: '14 day',
    trialPrice: 0,
    applePay: function (t, startingOn) {
      return {
        total: {
          label: t('applePay.total_label.7'),
          amount: 0,
        },
        displayItems: [
          {
            label: t(`applePay.displayItems.7.0`, { startingOn }),
            amount: 0,
          },
          {
            label: t(`applePay.displayItems.7.1`, { startingOn }),
            amount: 8999,
          },
        ],
      };
    },
  },
  '0_1month-8999_1year': {
    stripe: process.env.REACT_APP_STRIPE_LETTER_09,
    paypal: process.env.REACT_APP_PAYPAL_LETTER_09,
    price: '89.99',
    prevPrice: '129.99',
    per: '1 year',
    trial: '1 month',
    trialPrice: 0,
    applePay: function (startingOn) {
      return {
        total: {
          label: `1 Year subscription with ${this?.trial} trial period`,
          amount: 0,
        },
        displayItems: [
          {
            label: 'Special Offer: 1 month trial period',
            amount: 0,
          },
          {
            label: `1 Year Plan: Starting on ${startingOn}`,
            amount: 8999,
          },
        ],
      };
    },
  },
  '0_1month-2999_1year': {
    stripe: process.env.REACT_APP_STRIPE_LETTER_11,
    paypal: process.env.REACT_APP_PAYPAL_LETTER_11,
    price: '29.99',
    prevPrice: '39.99',
    per: '1 year',
    trial: '1 month',
    trialPrice: 0,
    applePay: function (startingOn) {
      return {
        total: {
          label: `1 Year subscription with ${this?.trial} trial period`,
          amount: 0,
        },
        displayItems: [
          {
            label: 'Special Offer: 1 months trial period',
            amount: 0,
          },
          {
            label: `1 Year Plan: Starting on ${startingOn}`,
            amount: 2999,
          },
        ],
      };
    },
  },
};
