import React from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import MainLayout from '../layouts/MainLayout';
import { DEBUG, languageList, QUIZ_ROUTE } from '../utils/constants';
import { Container, MenuItem, Select, ThemeProvider } from '@mui/material';
import theme2 from 'theme/theme2';
import { TemplateWrapper } from './TemplateWrapper';
import StripeSuccessPage from './StripeSuccessPage';
import StripeCancelPage from './StripeCancelPage';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const MainPage = React.lazy(() => import('pages/MainPage/MainPage'));
const DynamicPage = React.lazy(() => import('pages/DynamicPage/DynamicPage'));
const EmailPage = React.lazy(() => import('pages/EmailPage'));
const PaymentPage = React.lazy(() => import('pages/PaymentPage/PaymentPage'));
const OfferPage = React.lazy(() => import('pages/OfferPage/OfferPage'));
const EmailConfirmedPage = React.lazy(() => import('pages/EmailСonfirmedPage'));
const ManageSubscriptionsPage = React.lazy(() =>
  import('pages/ManageSubscriptionsPage/ManageSubscriptionsPage')
);
const ManageSubscriptionsPageOld = React.lazy(() =>
  import('pages/ManageSubscriptionsPage/ManageSubscriptionsPageOld')
);
const PayWallPage = React.lazy(() => import('pages/PayWallPage/PayWallPage'));
const ThanksPage = React.lazy(() => import('pages/ThanksPage/ThanksPage'));
const WelcomeLetter = React.lazy(() =>
  import('pages/WelcomeLetter/WelcomeLetter')
);
const LoginPage = React.lazy(() => import('pages/LoginPage/LoginPage'));
const AccountPage = React.lazy(() => import('pages/AccountPage'));
const PauseSubscriptionPage = React.lazy(() =>
  import('pages/CancelSubscription/PauseSubscriptionPage')
);
const PauseSubscriptionConfirmPage = React.lazy(() =>
  import('pages/CancelSubscription/PauseSubscriptionConfirmPage')
);
const ImprovePage = React.lazy(() =>
  import('pages/CancelSubscription/ImprovePage')
);
const DontUsePage = React.lazy(() =>
  import('pages/CancelSubscription/DontUsePage')
);
const TryAgainPage = React.lazy(() =>
  import('pages/CancelSubscription/TryAgainPage')
);
const ThoughtPage = React.lazy(() =>
  import('pages/CancelSubscription/ThoughtsPage')
);
const CreatingOfferPage = React.lazy(() =>
  import('pages/CancelSubscription/CreatingOfferPage')
);
const UpdatingSubscriptionPage = React.lazy(() =>
  import('pages/CancelSubscription/UpdatingSubscriptionPage')
);
const PersonalDiscountPage = React.lazy(() =>
  import('pages/CancelSubscription/PersonalDiscountPage')
);
const SorryToYourGoPage = React.lazy(() =>
  import('pages/CancelSubscription/SorryToYourGoPage')
);
const NeedMoreTimePage = React.lazy(() =>
  import('pages/CancelSubscription/NeedMoreTimePage')
);
const WantForFreePage = React.lazy(() =>
  import('pages/CancelSubscription/WantForFreePage')
);
const AlternativeAppsPage = React.lazy(() =>
  import('pages/CancelSubscription/AlternativeAppsPage')
);
const DidntLikeContentPage = React.lazy(() =>
  import('pages/CancelSubscription/DidntLikeContentPage')
);

const PrivacyPolicyMain = React.lazy(() =>
  import('pages/MainPage/PrivacyPolicy')
);
const CookiesPolicyMain = React.lazy(() =>
  import('pages/MainPage/CookiesPolicy')
);
const PartnersMain = React.lazy(() => import('pages/MainPage/Partners'));
const TermsOfServiceMain = React.lazy(() =>
  import('pages/MainPage/TermsOfService')
);
const SubscriptionPolicyMain = React.lazy(() =>
  import('pages/MainPage/SubscriptionPolicy')
);
const PrivacyPolicy = React.lazy(() =>
  import('pages/ManageSubscriptionsPage/PrivacyPolicy')
);
const CookiesPolicy = React.lazy(() =>
  import('pages/ManageSubscriptionsPage/CookiesPolicy')
);
const Partners = React.lazy(() =>
  import('pages/ManageSubscriptionsPage/Partners')
);
const TermsOfService = React.lazy(() =>
  import('pages/ManageSubscriptionsPage/TermsOfService')
);
const SubscriptionPolicy = React.lazy(() =>
  import('pages/ManageSubscriptionsPage/SubscriptionPolicy')
);

const AnimatedRoutes = () => {
  const location = useLocation();
  const { i18n } = useTranslation();

  return (
    <>
      {DEBUG && (
        <Select
          value={i18n.language}
          onChange={(e) => {
            i18n.changeLanguage(e.target.value);
            dayjs.locale(e.target.value);
          }}
          size="small"
          sx={{
            position: 'fixed',
            top: 8,
            left: 8,
            zIndex: 9999,
            backgroundColor: 'white',
          }}
        >
          {languageList.map((language) => (
            <MenuItem value={language} key={language}>
              {language}
            </MenuItem>
          ))}
        </Select>
      )}
      {/* <SwitchTransition mode="out-in" className="item">
        <CSSTransition
          key={location.pathname}
          timeout={350}
          classNames="item"
          unmountOnExit
          mountOnEnter
        > */}
      <Routes location={location}>
        <Route path="/" element={<MainPage />} />
        <Route path=":templateId" element={<TemplateWrapper />}>
          <Route index element={<Navigate to={QUIZ_ROUTE} />} />
          <Route path={QUIZ_ROUTE} element={<MainLayout />}>
            <Route index element={<DynamicPage />} />
            <Route path=":pageName" element={<DynamicPage />} />
          </Route>
          <Route path="email" element={<EmailPage />} />
          <Route path="paywall" element={<PayWallPage />} />
          <Route path="subscriptions" element={<PaymentPage />} />
          <Route path="offer" element={<OfferPage />} />
          <Route path="thanks" element={<ThanksPage />} />
        </Route>

        <Route
          path="/manageSubscriptionsOld"
          element={<ManageSubscriptionsPageOld />}
        />
        <Route
          path="/manageSubscriptions"
          element={
            <ThemeProvider theme={theme2}>
              <Container maxWidth="xs">
                <Outlet />
              </Container>
            </ThemeProvider>
          }
        >
          <Route path="" element={<ManageSubscriptionsPage />} />
          <Route path="pause" element={<PauseSubscriptionPage />} />
          <Route
            path="pause-confirm"
            element={<PauseSubscriptionConfirmPage />}
          />
          <Route path="how-improve" element={<ImprovePage />} />
          <Route path="dont-use" element={<DontUsePage />} />
          <Route path="try-again" element={<TryAgainPage />} />
          <Route path="share-thoughts" element={<ThoughtPage />} />
          <Route path="creating-offer" element={<CreatingOfferPage />} />
          <Route
            path="updating-subscription"
            element={<UpdatingSubscriptionPage />}
          />
          <Route path="personal-discount" element={<PersonalDiscountPage />} />
          <Route path="sorry-to-your-go" element={<SorryToYourGoPage />} />
          <Route path="need-more-time" element={<NeedMoreTimePage />} />
          <Route path="want-for-free" element={<WantForFreePage />} />
          <Route path="alternative-apps" element={<AlternativeAppsPage />} />
          <Route path="didnt-like-content" element={<DidntLikeContentPage />} />
          <Route path="privacy_policy" element={<PrivacyPolicy />} />
          <Route path="cookies_policy" element={<CookiesPolicy />} />
          <Route path="partners" element={<Partners />} />
          <Route path="terms_of_use" element={<TermsOfService />} />
          <Route path="subscription_policy" element={<SubscriptionPolicy />} />
        </Route>
        <Route path="/privacy_policy" element={<PrivacyPolicyMain />} />
        <Route path="/cookies_policy" element={<CookiesPolicyMain />} />
        <Route path="/partners" element={<PartnersMain />} />
        <Route path="/terms_of_use" element={<TermsOfServiceMain />} />
        <Route
          path="/subscription_policy"
          element={<SubscriptionPolicyMain />}
        />
        <Route path="/emailConfirmed" element={<EmailConfirmedPage />} />
        <Route path="/welcome" element={<WelcomeLetter />} />
        <Route path="/al:path" element={<></>} />
        <Route
          path="/login"
          element={
            <ThemeProvider theme={theme2}>
              <LoginPage />
            </ThemeProvider>
          }
        />
        <Route
          path="/account"
          element={
            <ThemeProvider theme={theme2}>
              <AccountPage />
            </ThemeProvider>
          }
        />

        <Route path="/stripe/cancel" element={<StripeCancelPage />} />
        <Route path="/stripe/success" element={<StripeSuccessPage />} />

        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* </CSSTransition>
      </SwitchTransition> */}
    </>
  );
};

export default AnimatedRoutes;
