export class TemplateRegistry {
  constructor() {
    this._templates = new Map();
    this._inheritance = new Map();
  }

  register(templateId, config, extendsFrom = null) {
    this._templates.set(templateId, {
      letter: templateId,
      templateId,
      ...config,
    });
    if (extendsFrom) {
      this._inheritance.set(templateId, extendsFrom);
    }
  }

  getConfig(templateId) {
    const config = this._templates.get(templateId);
    const parentId = this._inheritance.get(templateId);

    if (!config) return null;

    if (parentId) {
      const parentConfig = this.getConfig(parentId);
      return { ...parentConfig, ...config };
    }

    return config;
  }

  getTemplateIds() {
    return Array.from(this._templates.keys());
  }

  hasTemplate(templateId) {
    return this._templates.has(templateId);
  }

  areEqual(templateId1, templateId2) {
    if (templateId1 === templateId2) return true;

    if (this._getBaseTemplate(templateId1) === templateId2) return true;
    if (this._getBaseTemplate(templateId2) === templateId1) return true;

    // Check if they share the same base template
    return (
      this._getBaseTemplate(templateId1) === this._getBaseTemplate(templateId2)
    );
  }

  _getBaseTemplate(templateId) {
    let current = templateId;
    while (this._inheritance.has(current)) {
      current = this._inheritance.get(current);
    }
    return current;
  }
}
