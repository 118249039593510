import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { useFormContext } from 'react-hook-form';
import UnCheckedIcon from 'components/Icons/UnCheckedIcon';
import CheckedIcon from 'components/Icons/CheckedIcon';
import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import TextGradientWrapper from './TextGradientWrapper';

const MultiCheckbox = ({
  pageName,
  label,
  helperText,
  questions = [],
  variant = 'text',
}) => {
  const { t } = useTranslation();
  const methods = useFormContext();

  const [checked, setChecked] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    methods.setValue(pageName, newChecked);
  };

  useEffect(() => {
    const value = methods.watch(pageName) || [];
    setChecked(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack mt={'40px'} gap={4}>
      <Stack gap={'12px'}>
        <Typography
          color="#2F2F37"
          sx={{
            width: '100%',

            fontFamily: 'Montserrat',
            fontSize: '24px',
            fontWeight: 700,
            letterSpacing: '0em',
            textAlign: 'left',
          }}
        >
          <Trans
            t={t}
            i18nKey={label}
            components={{
              gradient: (
                <TextGradientWrapper gradientColor="linear-gradient(95.49deg, #FF006B 10.61%, #8F00FF 60.31%, #01A4FF 95.66%)" />
              ),
            }}
          />
        </Typography>
        {helperText && (
          <Typography
            fontSize={14}
            fontWeight={500}
            letterSpacing={'-0.02em'}
            color={'#8293AC'}
          >
            {t(helperText)}
          </Typography>
        )}
      </Stack>
      <List
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: variant === 'contained' ? '10px' : '0px',
          bgcolor: 'background.paper',
        }}
      >
        {questions.map(({ value, label: qLabel }) => {
          const labelId = `checkbox-list-label-${value}`;
          const isChecked = checked.indexOf(value) !== -1;
          return (
            <ListItem key={value} disablePadding>
              <ListItemButton
                role={undefined}
                onClick={handleToggle(value)}
                dense
                sx={{
                  borderRadius: '16px',
                  '&:hover': {
                    backgroundColor: '#8293AC1A',
                    cursor: 'pointer',
                  },
                  ...(variant === 'contained' && {
                    p: '15px 16px',
                    bgcolor: '#8293AC1A',
                    flexDirection: 'row-reverse',
                  }),
                }}
              >
                <ListItemIcon
                  sx={{
                    '&.MuiListItemIcon-root': {
                      minWidth: 'unset',
                    },
                  }}
                >
                  <Checkbox
                    edge="start"
                    checked={isChecked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    icon={<UnCheckedIcon />}
                    checkedIcon={<CheckedIcon />}
                    sx={{
                      ...(variant === 'contained' && {
                        p: 0,
                      }),
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={t(qLabel)}
                  sx={{
                    ...(variant === 'contained' && {
                      margin: 0,
                    }),
                  }}
                  primaryTypographyProps={{
                    fontFamily: 'Montserrat',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '26px',
                    letterSpacing: '-0.02em',
                    textAlign: 'left',
                    ...(variant === 'contained' && {
                      color: isChecked ? '#02082D' : '#465063',
                      fontWeight: isChecked ? 500 : 400,
                    }),
                  }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Stack>
  );
};

export default MultiCheckbox;
