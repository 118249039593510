import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {
  Outlet,
  useParams,
  useNavigate,
  Navigate,
  useSearchParams,
} from 'react-router-dom';
import { QUIZ_ROUTE } from '../utils/constants';
import { useForm, FormProvider } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ContinueButton from './components/ContinueButton';
import trackAmplitude from '../services/analytics';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AuthCookieContext } from 'utils/context';
import { CW_SEGMENT } from 'utils/AmplitudeTrackEvents';
import SelectButton from 'components/SelectButton';

export const QUIZ_FORM_STORAGE_KEY = 'quiz_form_data';

const MainLayout = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const { templateConfig, userInfo } = useContext(AuthCookieContext);
  const uiConfig = templateConfig?.uiConfig;
  const quizConfig = templateConfig?.quizConfig;

  const { pageName } = useParams();

  const savedFormData =
    typeof window !== 'undefined'
      ? JSON.parse(localStorage.getItem(QUIZ_FORM_STORAGE_KEY) || '{}')
      : {};
  const methods = useForm({
    defaultValues: savedFormData,
  });
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);

  const formPageValues = methods.watch(pageName);
  const allFormValues = methods.watch();

  // Save form data to localStorage whenever it changes
  useEffect(() => {
    if (!isEmpty(allFormValues)) {
      localStorage.setItem(
        QUIZ_FORM_STORAGE_KEY,
        JSON.stringify(allFormValues)
      );
    }
  }, [allFormValues]);

  const handleClickContinue = () => {};

  const onSubmit = (data) => {
    setDisable(true);
    const eventName = quizConfig[pageName]?.eventName;

    // track user answer to amplitude
    if (!isEmpty(data[pageName] || formPageValues)) {
      eventName &&
        trackAmplitude(`${eventName}_answer`, {
          value: data[pageName] || formPageValues,
        });
    }

    localStorage.setItem(QUIZ_FORM_STORAGE_KEY, JSON.stringify(data));

    // clickToScroll('root > .MuiContainer-root');
    navigate(`../${QUIZ_ROUTE}/${quizConfig[pageName]?.nextPage}`);
  };

  const NextButton = quizConfig[pageName]?.ButtonType || ContinueButton;

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 0);
    setDisable(false);
  }, [pageName]);

  useEffect(() => {
    const eventName = templateConfig.quizConfig[pageName]?.eventName;
    eventName && trackAmplitude(eventName);
  }, [pageName, templateConfig]);

  useEffect(() => {
    if (pageName === templateConfig.quizConfig.startPage) {
      const params = searchParams.size
        ? Object.fromEntries(searchParams.entries())
        : { params: 'not found' };
      console.log(params);
      // trackAmplitude(CW_INIT, params);
      trackAmplitude(CW_SEGMENT, {
        funnel_id: userInfo?.templateId,
        ...params,
      });
    }
  }, [
    pageName,
    searchParams,
    templateConfig.quizConfig.startPage,
    userInfo?.templateId,
  ]);

  console.log(pageName);

  if (!pageName) {
    return (
      <Navigate
        to={`../${QUIZ_ROUTE}/${templateConfig.quizConfig.startPage}`}
      />
    );
  }

  if (pageName === 'AnalyzingPage' || pageName === 'AllInOneApp') {
    return <Outlet />;
  }

  const showNextButton =
    !quizConfig[pageName]?.hideContinueButton &&
    (quizConfig[pageName]?.QuestionButtonType === SelectButton ||
    ['Page23', 'Page24'].includes(pageName)
      ? !uiConfig?.quizPage?.SelectButton?.immediateSelect
      : true);

  return (
    <FormProvider {...methods}>
      <Container
        maxWidth="xs"
        disableGutters
        sx={{
          // height: '100vh',
          px: 20 / 8,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
          flex={1}
          pb={20}
        >
          <Outlet
            context={{
              navigate,
              pageName,
              handleClickContinue,
            }}
          />
        </Box>

        <Box
          sx={{
            position: 'fixed',
            left: 0,
            right: 0,
            bottom: 0,
            background: '#fff',
            boxShadow: '0px 0px 15px 10px #fff',
          }}
        >
          <Container
            maxWidth="xs"
            disableGutters
            sx={{
              px: 20 / 8,
            }}
          >
            <Stack spacing={2} pb={20 / 8}>
              {['Page4', 'Page7', 'Page16', 'Page20', 'Page27'].includes(
                pageName
              ) && (
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    letterSpacing: '-0.02em',
                    textAlign: 'center',
                  }}
                >
                  {/*{pageName === 'Page4' && <>Already with SMMHUB? <strong>Sign in</strong></>}*/}
                  {/*{pageName === 'Page7' && <>Already with SMMHUB? <strong>Sign in</strong></>}*/}
                  {pageName === 'Page16' &&
                    `*${t('common.according_to_survey')}`}
                  {pageName === 'Page20' &&
                    `*${t('common.according_to_survey')}`}
                  {pageName === 'Page27' &&
                    `*${t('common.according_to_survey')}`}
                </Typography>
              )}
              {showNextButton && (
                <NextButton
                  text={t(quizConfig[pageName]?.btnText)}
                  disabled={
                    (!(formPageValues?.length > 0) &&
                      quizConfig.hasOwnProperty(pageName) &&
                      quizConfig[pageName].hasOwnProperty('questions')) ||
                    (!methods.formState.isValid && pageName === 'Page28') ||
                    disable
                  }
                  onClick={methods.handleSubmit(onSubmit)}
                />
              )}
            </Stack>
            {['Page31'].includes(pageName) && (
              <Typography
                pb={2}
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '10px',
                  fontWeight: 400,
                  lineHeight: '16px',
                  letterSpacing: '-0.02em',
                  color: '#8293ac',
                }}
              >
                {t('dynamicPage.final_results_may_vary')}
              </Typography>
            )}
          </Container>
        </Box>
      </Container>
    </FormProvider>
  );
};

export default MainLayout;
